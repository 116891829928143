<template>
  <div>
    <default-layout>
      <section class="inner-page">
        <div class="container" style="text-align: justify;">
          <img
            src="@/assets/imagenes/LOGO  ACD CONSULTORES.png"
            alt="logo"
            style="width: 200px; height: auto;"
          />
          <p></p>
          <h2>AVISO DE PRIVACIDAD</h2>
          <br />

          Con fundamento a lo Previsto en los Artículos 15, 16 y 17 de la “Ley
          Federal de Protección de Datos Personales en Posesión de los
          Particulares”, hacemos de conocimiento que “ACD CONSULTORES, SOCIEDAD
          anónima DE CAPITAL VARIABLE” es una empresa legalmente constituida
          bajo las leyes mexicanas, con domicilio legal en Avenida Francisco I.
          Madero 241 L-11 M-21 “Locales Comerciales”, Interior “C”, Col.
          Venustiano Carranza, C.P. 77012, Chetumal, Municipio de Othón P.
          Blanco, Quintana Roo; y por medio de este aviso de privacidad hacemos
          de su conocimiento que somos responsables de recabar sus datos
          personales, así como del uso que se les dé a los mismos y de su
          protección. Su información personal será utilizada para proveer los
          servicios, productos e información que ha solicitado, comunicarle
          sobre cambios en los mismos y evaluar la calidad del servicio que le
          brindamos. Para las finalidades antes mencionadas requerimos obtener
          los siguientes datos personales:
          <br />

          <ul>
            <li>Nombre Completo.</li>
            <li>Domicilio.</li>
            <li>Registro Federal de Contribuyentes.</li>
            <li>Teléfonos de Hogar, Oficina y móviles.</li>
            <li>Correo Electrónico.</li>
          </ul>

          Estos serán utilizados única y exclusivamente para los siguientes
          fines:

          <ul>
            <li>Campañas de Publicidad.</li>
            <li>Campañas de Fidelidad.</li>
            <li>Información y Prestación de Servicios.</li>
            <li>Actualización de la Base de Datos.</li>
            <li>
              Cualquier finalidad análoga o compatible con las anteriores.
            </li>
          </ul>

          En el caso de Datos sensibles, tales como:

          <ul>
            <li>
              Datos Financieros (Ingresos, Estados de Cuenta, y demás
              relacionados).
            </li>
            <li>
              Datos Patrimoniales (Bienes Materiales, Inmuebles, y demás
              relacionados).
            </li>
            <li>
              Datos Personales (Cónyuge, Estado Civil, Nacionalidad, Educación,
              Hijos, y demás relacionados).
            </li>
            <li>
              Referencias familiares y no familiares (Nombre, Dirección,
              Teléfono, relación, etc.).
            </li>
          </ul>
          Estos serán utilizados única y exclusivamente para los siguientes
          fines:

          <ul>
            <li>Cualquier finalidad análoga o compatible con la anterior.</li>
          </ul>

          Para prevenir el acceso no autorizado a sus datos personales y con el
          fin de asegurar que la información sea utilizada para los fines
          establecidos en este aviso de privacidad, nos hacemos responsables de
          utilizar los datos proporcionados para los fines ya establecidos y ser
          muy cuidadosos con la información que nos ha proporcionado, por lo que
          le damos la seguridad de que serán utilizados por nuestro personal con
          estricto cuidado y bajo las políticas que establece la empresa, con la
          finalidad de evitar el uso o divulgación no autorizados de sus datos,
          permitiéndonos tratarlos debidamente.
          <br />
          <br />
          Es importante informarle que usted tiene derecho al acceso,
          rectificación y cancelación de sus datos personales y oponerse al
          tratamiento de los mismos o a revocar el consentimiento que para dicho
          fin nos haya otorgado.
          <br />
          <br />
          Para ello es necesario se dirija la solicitud en los términos que
          marca la ley en mención, en su artículo 29 al Departamento de
          Servicios a Cliente, ubicado en Avenida Francisco I. Madero 241 L-11
          M-21 “Locales Comerciales”, Interior “C”, Col. Venustiano Carranza,
          C.P. 77012, Chetumal, Municipio de Othón P. Blanco, Quintana Roo o vía
          correo electrónico contacto@acdnomina.com.
          <br />
          <br />
          Por otra parte, hacemos de su conocimiento que “ACD CONSULTORES,
          SOCIEDAD anónima DE CAPITAL VARIABLE, no transfiere información
          personal a terceros.
          <br />
          <br />
          En caso de que no obtengamos su oposición expresa para que sus datos
          personales sean transferidos en la forma y términos antes descrita,
          entenderemos que ha otorgado su consentimiento en forma tácita para
          ello.
          <br />
          <br />
          Importante: Cualquier modificación de este aviso de privacidad podrá
          consultarlo en nuestra pagina web www.acdnomina.com.
          <br />
          <br />
          Fecha última actualización: Enero de 2020.
        </div>
        <br />
        <div style="padding-left:120px;">
          <a
            class=" btn btn-xl"
            style="background-color: #323387; color:white;"
            href="/"
            >Regresar</a
          >
        </div>
        <br />
        <br />
        <br />
      </section>
    </default-layout>
  </div>
</template>
<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
export default {
  name: "PolitticasPage",
  components: {
    DefaultLayout
  }
};
</script>
